import { Box, Typography, Button, TextField, Autocomplete, Checkbox } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import useSetState from 'hooks/useSetState'
import { ExpandMore } from '@mui/icons-material'
import { isValidPassword, isValidEmail } from 'utils/common'
import Images from 'assets'
import { register, loginByPassword } from 'apis'
import Storages from 'utils/Storages'
import styles from './SignUp.module.less'
import { actions } from 'app/profileSlice'
import { useAppDispatch } from 'app/hooks'
import Config from 'constants/Config'
import CustomSelect, { CustomSelectChoice } from '../components/common/CustomSelect'
import React, { useCallback, useMemo } from 'react'
import useGetNoteTemplates from 'hooks/useGetNoteTemplate'
import HIPAADialog from 'components/modal/HIPAA'

const MedicalSpecialtyOption = ['A', 'B']

export default function SignUp() {
  const dispatch = useAppDispatch()
  const { template } = useGetNoteTemplates()
  const [state, setState] = useSetState({
    step: 0,
    FullName: '',
    MedicalSpecialty: '',
    Country: 'USA',
    checked: true,
    Email: '',
    NoteTemplate: '',
    Password: '',
    ClinicName: '',
    ConfirmPassword: '',
    hasError: false,
    showHipaa: false,
    agreementClicked: false,
    baaClicked: false,
  })
  const {
    step,
    FullName,
    ClinicName,
    MedicalSpecialty,
    NoteTemplate,
    Country,
    checked,
    Email,
    Password,
    ConfirmPassword,
    hasError,
    showHipaa,
    agreementClicked,
    baaClicked,
  } = state
  const navigate = useNavigate()
  const location = useLocation()
  const { su } = useMemo(() => location.state || {}, [location.state])

  const onSignIn = () => {
    navigate('/login')
  }
  const onChangeNoteTemplate = useCallback((value: CustomSelectChoice | null) => {
    value && setState({ NoteTemplate: value.value as string })
  }, [])
  const onCreateAccount = () => {
    let hasError = false
    if (!isValidEmail(Email)) {
      hasError = true
    }
    if (!isValidPassword(Password).checked) {
      hasError = true
    }
    if (ConfirmPassword !== Password) {
      hasError = true
    }
    setState({ hasError })
    if (hasError) return
    register({
      Email,
      Password,
      FullName,
      Country,
      ClinicName,
      NoteTemplate,
      SalesUserId: su,
      Channel: Config.channel,
    }).then((res) => {
      loginByPassword({ UserName: Email, Password }).then((res: any) => {
        Storages.setAuthToken(res.UserToken)
        dispatch(actions.loginSuccess(res))
        navigate('/')
      })
    })
  }
  const termsChecked = agreementClicked && baaClicked

  if (step === 0) {
    return (
      <>
        <Box className={styles.container}>
          <img src={Config.logo} alt="logo" className={styles.logo} />
          <Typography className={styles.welcome}>Welcome!</Typography>
          <Typography className={styles.text}>
            Get smart notes from your consultation in <br />
            minutes
          </Typography>
          <Button variant="contained" className={styles.button} onClick={() => setState({ step: 1 })}>
            Free Sign up
          </Button>
          <Typography className={styles.signInRow}>
            Already have an account?{' '}
            <span className={styles.signIn} onClick={onSignIn}>
              Sign in
            </span>
          </Typography>
        </Box>
        <Box className={styles.hipaa} onClick={() => setState({ showHipaa: true })}>
          <img src={Images.HIPAA} alt="" style={{ marginRight: '8px', height: '24px' }} />
          We are HIPAA Compliant!
        </Box>
        {showHipaa && <HIPAADialog onClose={() => setState({ showHipaa: false })} />}
      </>
    )
  }
  if (step === 1) {
    return (
      <Box className={styles.stepContainer}>
        <Typography className={styles.header}>{Config.appName}</Typography>
        <Box className={styles.stepBox}>
          {[1, 2, 3].map((i) => {
            const opacity = i <= step ? '20%' : '8%'
            return <Box key={i} className={styles.step} style={{ opacity }} />
          })}
        </Box>

        <Box className={styles.card}>
          <Typography className={styles.cardTitle}>Tell me about yourself</Typography>
          <Typography className={styles.label}>What&apos;s your name</Typography>
          <TextField
            value={FullName}
            variant="outlined"
            placeholder="Your name"
            fullWidth
            onChange={(e) => setState({ FullName: e.target.value })}
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
          />
          <Typography className={styles.label}>Clinic Name</Typography>
          <TextField
            value={ClinicName}
            variant="outlined"
            placeholder="Please enter"
            fullWidth
            onChange={(e) => setState({ ClinicName: e.target.value })}
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
          />
          <Typography className={styles.label}>Note Template</Typography>
          <CustomSelect
            label=""
            sx={{ width: '100%', marginBottom: '24px' }}
            value={template?.find((item1: CustomSelectChoice) => item1.value === NoteTemplate)}
            onChange={onChangeNoteTemplate}
            choices={template || []}
          />
          {/*<Typography className={styles.label}>Medical Specialty</Typography>
          <Autocomplete
            fullWidth
            disableClearable
            value={MedicalSpecialtyOption.find((option) => option === MedicalSpecialty) || ''}
            options={MedicalSpecialtyOption}
            getOptionLabel={(option) => option}
            onChange={(_, value) => setState({ MedicalSpecialty: value as string })}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Please select"
                InputProps={{
                  ...params.InputProps,
                  classes: { root: styles.inputRoot },
                }}
              />
            )}
            className={styles.input}
            popupIcon={<ExpandMore />}
          />*/}

          <Typography className={styles.label}>Country of practice</Typography>
          <Autocomplete
            fullWidth
            disableClearable
            value={Country}
            options={['USA', 'Canada']}
            getOptionLabel={(option) => option}
            onChange={(_, value) => setState({ Country: value as string })}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Please select"
                InputProps={{
                  ...params.InputProps,
                  classes: { root: styles.inputRoot },
                }}
              />
            )}
            className={styles.input}
            popupIcon={<ExpandMore />}
          />

          <Box className={styles.checkRow}>
            <Checkbox
              checked={checked}
              size="small"
              className={styles.checkBox}
              onChange={(e) => setState({ checked: e.target.checked })}
            />
            <span>I'm a healthcare professional</span>
          </Box>
          <Box
            className={styles.checkRow}
            style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 16 }}
          >
            <Checkbox
              checked={termsChecked}
              size="small"
              className={styles.checkBox}
              onChange={() => {
                if (!agreementClicked) {
                  window.open(Config.agreement, '_blank')
                  return setState({ agreementClicked: true })
                }
                if (!baaClicked) {
                  window.open(Config.terms, '_blank')
                  setState({ baaClicked: true })
                }
              }}
            />
            <Typography className={styles.agreement}>
              I agree to the
              <a
                href={Config.agreement}
                target="_blank"
                rel="noreferrer"
                onClick={() => setState({ agreementClicked: true })}
              >
                Subscriber Agreement{' '}
              </a>
              and
              <a href={Config.terms} target="_blank" rel="noreferrer" onClick={() => setState({ baaClicked: true })}>
                {'Term of Service \n with BAA.'}
              </a>
            </Typography>
          </Box>
          <Button
            variant="contained"
            className={`${styles.button} ${styles.next}`}
            onClick={() => setState({ step: 2 })}
            disabled={!termsChecked || !checked || !FullName || !Country || !NoteTemplate}
            classes={{ disabled: styles.disabled }}
          >
            Next
          </Button>
        </Box>
      </Box>
    )
  }

  if (step === 2) {
    return (
      <Box className={styles.stepContainer}>
        <Typography className={styles.header}>{Config.appName}</Typography>
        <Box className={styles.stepBox}>
          {[1, 2, 3].map((i) => {
            const opacity = i <= step ? '20%' : '8%'
            return <Box key={i} className={styles.step} style={{ opacity }} />
          })}
        </Box>

        <Box className={styles.card}>
          <Typography className={styles.cardTitle}>Create your account</Typography>
          <Typography className={styles.label}>Email</Typography>
          <TextField
            value={Email}
            variant="outlined"
            placeholder="Email"
            fullWidth
            onChange={(e) => setState({ Email: e.target.value })}
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            error={(hasError && !isValidEmail(Email)) || false}
            helperText={hasError && !isValidEmail(Email) && 'Please enter a valid email'}
          />

          <Typography className={styles.label}>Password</Typography>
          <TextField
            variant="outlined"
            placeholder="Password"
            autoComplete="new-password"
            fullWidth
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            type="password"
            onChange={(e) => setState({ Password: e.target.value })}
            error={(hasError && !isValidPassword(Password).checked) || false}
            helperText={hasError && !isValidPassword(Password).checked && isValidPassword(Password).message}
          />

          <Typography className={styles.label}>Confirm Password</Typography>
          <TextField
            variant="outlined"
            placeholder="Please enter"
            fullWidth
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            type="password"
            onChange={(e) => setState({ ConfirmPassword: e.target.value })}
            error={hasError && ConfirmPassword !== Password}
            helperText={hasError && ConfirmPassword !== Password && 'Password does not match'}
          />

          {/* <Box className={styles.checkRow}>
            <Checkbox checked={checked} size="small" onChange={(e) => setState({ checked: e.target.checked })} />
            <span>
              By creating an account you agree to the
              <span className={styles.signIn}> Terms of Service </span> and
              <span className={styles.signIn}> Privacy Policy</span>.
            </span>
          </Box> */}

          <Button
            variant="contained"
            className={`${styles.button} ${styles.next}`}
            onClick={() => onCreateAccount()}
            disabled={!checked || !Email || !Password || !ConfirmPassword}
            classes={{ disabled: styles.disabled }}
          >
            Create an account
          </Button>
        </Box>
      </Box>
    )
  }

  return null
}
