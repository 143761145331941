import React, { useCallback, useState } from 'react'
import { Box, Button, Dialog as DialogUi, IconButton, Typography, Drawer } from '@mui/material'
import classes from './RegenerateDialog.module.less'
import { Close } from '@mui/icons-material'
import CustomSelect, { CustomSelectChoice } from '../common/CustomSelect'
import useGetNoteTemplates from 'hooks/useGetNoteTemplate'
import { LANGUAGE_ENUM } from '../../constants/common'
import LabelItem from '../common/LabelItem'

interface Props {
  onClose: () => void
  language?: string
  onStart?: (language: string) => void
}

const SwitchLanguageDialog: React.FC<Props> = ({ onClose, language, onStart }) => {
  const [open, setOpen] = useState(true)
  const [data, setData] = useState(language)

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onStartCall = () => {
    onCancel()
    onStart?.(data as string)
  }

  const onCancel = () => {
    removeDialog()
  }
  const onChangeLanguage = useCallback((value: CustomSelectChoice | null) => {
    value && setData(value.value as string)
  }, [])
  return (
    <DialogUi open={open} onClose={removeDialog} classes={{ paper: classes.container }}>
      <IconButton onClick={onCancel} classes={{ root: classes.close }}>
        <Close htmlColor="#211F1F" />
      </IconButton>
      <Typography className={classes.title}>Start A New Consultation</Typography>
      <Typography className={classes.desc}>Please select a language for your upcoming Consultation</Typography>
      <LabelItem label="Switch Language" className={classes.gapTop36}>
        <CustomSelect
          label=""
          sx={{ width: '100%' }}
          value={LANGUAGE_ENUM?.find((item1: CustomSelectChoice) => item1.value === data)}
          onChange={onChangeLanguage}
          choices={LANGUAGE_ENUM || []}
        />
      </LabelItem>
      <Button
        variant="contained"
        sx={{ width: '225px', alignSelf: 'center' }}
        onClick={onStartCall}
        color="primary"
        className={classes.btn}
        disabled={!data}
      >
        Start now
      </Button>
    </DialogUi>
  )
}

export const H5SwitchLanguageDialog: React.FC<Props> = ({ onClose, language, onStart }) => {
  const [data, setData] = useState(language)
  const [open, setOpen] = useState(true)

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onStartCall = () => {
    onCancel()
    onStart?.(data as string)
  }

  const onCancel = () => {
    removeDialog()
  }
  const onChangeLanguage = useCallback((value: CustomSelectChoice | null) => {
    value && setData(value.value as string)
  }, [])
  return (
    <Drawer anchor="bottom" open={open} onClose={removeDialog} classes={{ paper: classes.h5container }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className={classes.title} style={{ fontSize: 24 }}>
          Start A New Consultation
        </Typography>
        <Close htmlColor="#211F1F" sx={{ fontSize: 30 }} onClick={onCancel} />
      </Box>
      <Typography className={classes.h5desc}>Please select a language for your upcoming Consultation</Typography>
      <LabelItem label="Switch Language" className={classes.gapTop36}>
        <CustomSelect
          label=""
          sx={{ width: '100%' }}
          value={LANGUAGE_ENUM?.find((item1: CustomSelectChoice) => item1.value === data)}
          onChange={onChangeLanguage}
          choices={LANGUAGE_ENUM || []}
        />
      </LabelItem>
      <Button
        variant="contained"
        sx={{ width: '225px', alignSelf: 'center' }}
        onClick={onStartCall}
        color="primary"
        className={classes.btn}
        disabled={!data}
      >
        Start now
      </Button>
    </Drawer>
  )
}

export default SwitchLanguageDialog
