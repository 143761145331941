import React, { useRef } from 'react'
import { Button, Typography, TextField, Box, ClickAwayListener } from '@mui/material'
import { ClearRounded } from '@mui/icons-material'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import Images from 'assets'
import { useSetState } from 'hooks'
import classes from './PopupFilter.module.less'
import PopupState from 'material-ui-popup-state'
import { usePopupState, bindPopover, bindHover } from 'material-ui-popup-state/hooks'

export interface FilterData {
  name: string
  label: string
  multiple?: boolean
  options: {
    label: string
    value: string
  }[]
}
export type FilterDataOptions = Record<string, FilterData>
interface TabPanelProps {
  filterData?: Record<string, any[]> // search 为保留字
  filterDataOptions: FilterDataOptions
  setFilters: (filters: Record<string, any[]>) => void
}

const PopupFilter: React.FC<TabPanelProps> = (props) => {
  const { filterDataOptions, filterData = {}, setFilters } = props
  const [{ reRender }, setState] = useSetState({ reRender: false })
  const anchorRef = useRef(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const popupState = usePopupState({ variant: 'popover', popupId: 'filterPopover' })

  return (
    <div className={classes.container}>
      <div className={classes.clearContainer}>
        {Object.entries(filterData).map(([key, valueList]) => {
          return valueList.map(({ label, value }) => (
            <Button
              key={value + key}
              endIcon={<ClearRounded htmlColor="#7F7F7F" />}
              onClick={() => {
                filterData[key] = filterData[key].filter((v) => v.value !== value)
                setFilters(filterData)
                setState({ reRender: !reRender })
              }}
              className={classes.clearItem}
            >
              {label}
            </Button>
          ))
        })}
      </div>

      <div
        style={{ cursor: 'pointer' }}
        ref={anchorRef}
        onClick={(e) => {
          popupState.toggle()
          e.stopPropagation()
        }}
      >
        <img src={Images.filter} alt="filter" style={{ display: 'block' }} />
      </div>

      <ClickAwayListener
        onClickAway={() => {
          popupState.isOpen && popupState.close()
        }}
      >
        <HoverPopover
          {...bindPopover(popupState)}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ paper: classes.hoverPaper }}
        >
          <TextField
            variant="outlined"
            placeholder="Search Title"
            defaultValue={filterData['search']?.[0]?.value || ''}
            className={classes.searchInput}
            InputProps={{
              classes: { input: classes.searchInputRoot },
            }}
            onChange={(e) => {
              if (timerRef.current) {
                clearTimeout(timerRef.current)
              }
              const timer = setTimeout(() => {
                timerRef.current = timer
                filterData['search'] = [{ label: e.target.value, value: e.target.value }]
                setFilters(filterData)
                setState({ reRender: !reRender })
                clearTimeout(timerRef.current)
              }, 1500)
            }}
          />
          {Object.entries(filterDataOptions).map(([key, { name, label, options, multiple }]) => {
            return (
              <PopupState variant="popover" popupId={key} key={key}>
                {(popupState1) => {
                  return (
                    <Box {...bindHover(popupState1)}>
                      <Typography className={classes.popoverItemLabel}>{label}</Typography>
                      <HoverPopover
                        {...bindPopover(popupState1)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        sx={{ display: options?.length === 0 ? 'none' : '', maxHeight: '300px' }}
                      >
                        {options.map(({ label, value }) => {
                          filterData[name] = filterData[name] || []
                          const checked = filterData[name].findIndex((v) => v.value === value) > -1
                          return (
                            <Typography
                              key={value}
                              className={classes.popoverItemLabel}
                              sx={{ backgroundColor: checked ? '#f7f8fa' : 'transparent' }}
                              onClick={() => {
                                if (multiple) {
                                  if (checked) {
                                    filterData[name] = filterData[name].filter((v) => v.value !== value)
                                  } else {
                                    filterData[name].push({ label, value })
                                  }
                                  setFilters(filterData)
                                  setState({ reRender: !reRender })
                                  return popupState.close()
                                }
                                filterData[name] = [{ label, value }]
                                setFilters(filterData)
                                setState({ reRender: !reRender })
                                return popupState.close()
                              }}
                            >
                              {label}
                            </Typography>
                          )
                        })}
                      </HoverPopover>
                    </Box>
                  )
                }}
              </PopupState>
            )
          })}
        </HoverPopover>
      </ClickAwayListener>
    </div>
  )
}
export default React.memo(PopupFilter)
