import { Box, IconButton, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import IcdEdit from './IcdEdit'
import classes from './IcdList.module.less'
import Images from '../../assets'
import { ICDDataITF } from '../../types/transcription'
import { updateConsultation } from '../../apis'
import SnackBar from '../snackBar'
import classnames from 'classnames'
import { isMobile } from '../../utils/common'
interface Props {
  cId: string
  icds?: ICDDataITF[]
}
const IcdList: React.FC<Props> = ({ cId, icds }) => {
  const [list, setList] = useState(icds || [])
  const onConfirm = useCallback((data: ICDDataITF[]) => {
    setList(data)
  }, [])
  const onDelete = (pcId: string) => {
    const index = list.findIndex((item) => item.pcId === pcId)
    list.splice(index, 1)
    updateConsultation({ ConsultationId: cId, Icds: list }).then(() => {
      SnackBar({ type: 'success', msg: 'Deleted successfully' })
      setList([...list])
    })
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.headerText}>ICD-10 CODES </Typography>
        <IcdEdit icds={list} cId={cId} onConfirm={onConfirm} />
      </Box>
      {list?.map((item) => {
        const { pcId, Name, Code } = item
        return (
          <Box className={classes.item} key={pcId + Code}>
            <Typography
              className={classnames(classes.itemName, { [classes.itemNameMobile]: isMobile })}
            >{`${Name}(${Code})`}</Typography>
            <IconButton className={classes.buttonDelete} onClick={() => onDelete(pcId)}>
              <img
                src={Images.delete}
                className={classnames(classes.itemIcon, { [classes.itemIconMobile]: isMobile })}
                alt="delete"
              />
            </IconButton>
          </Box>
        )
      })}
    </Box>
  )
}

export default IcdList
