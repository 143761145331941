import * as echarts from 'echarts/core'
import { TitleComponent, ToolboxComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

import type {
  // 系列类型的定义后缀都为 SeriesOption
  BarSeriesOption,
  LineSeriesOption,
} from 'echarts/charts'
import type {
  // 组件类型的定义后缀都为 ComponentOption
  TitleComponentOption,
  TooltipComponentOption,
  GridComponentOption,
  DatasetComponentOption,
} from 'echarts/components'
import type { ComposeOption } from 'echarts/core'
import { useEffect } from 'react'

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
export type ECOption = ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
>

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
])

interface Props {
  id: string
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  option: ECOption
  className?: string
  style?: React.CSSProperties
}

const Echarts: React.FC<Props> = (props) => {
  const { id, width, height, option, className, style = {} } = props
  useEffect(() => {
    const chartDom = document.getElementById(`echarts_${id}`)
    const myChart = echarts.init(chartDom)
    myChart.clear()
    option && myChart.setOption(option, true, true)
  }, [option, id])
  return (
    <div
      id={`echarts_${id}`}
      style={{ width: width || '300px', height: height || '300px', ...style }}
      className={className}
    />
  )
}

export default Echarts
