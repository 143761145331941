import Images from '../assets'

const ajami = 'copilot.ajami.ai'
// const ajami = 'localhost:3006'
const Config = {
  env: process.env.REACT_APP_APP_ENV as 'DEV' | 'PROD',
  baseUrl: process.env.REACT_APP_API_HOST as string,
  deepgramKey: process.env.REACT_APP_DEEPGRAM_KEY as string,
  s3Bucket: process.env.REACT_APP_S3_BUCKET as string,
  s3Url: process.env.REACT_APP_S3_BUCKET_URL as string,
  s3PrivateBucket: process.env.REACT_APP_S3_PRIVATE_BUCKET as string,
  s3PrivateUrl: process.env.REACT_APP_S3_PRIVATE_BUCKET_URL as string,
  awsRegion: 'us-east-1',
  s3AccessKeyId: 'AKIA2UC3DGUOTXL33XUT',
  s3SecretAccessKey: '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',
  s3PublicAccessKeyId: 'AKIA2UC3DGUOUZWKDC5D',
  s3PublicSecretAccessKey: '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',
  agreement: 'https://dev-env-pecker.s3.amazonaws.com/file/Subscriber.Agreement.html',
  terms: 'https://dev-env-pecker.s3.amazonaws.com/file/Terms.of.Service.With.BAA.html',
  dingAudio: 'https://dev-env-pecker.s3.us-east-1.amazonaws.com/file/ding.mp3',
  logo: window.origin.includes(ajami) ? Images.logoAjami : Images.logo,
  logoMenu: window.origin.includes(ajami) ? Images.logoMenuAjami : Images.logoMenu,
  channel: window.origin.includes(ajami) ? 'ajami' : 'woodpecker',
  appName: window.origin.includes(ajami) ? 'AJAMI Copliot' : 'Woodpecker Copilot',
}

export default Config
