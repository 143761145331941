import React, { useEffect } from 'react'
import Note from './Note'
import { IConsultation, ISmartNote } from 'types/transcription'
import classes from './SmartNote.module.less'
import Markdown from 'react-markdown'
import { Box, Button, Typography } from '@mui/material'
import { useAppDispatch } from 'app/hooks'
import { actions } from 'app/profileSlice'
import { useSetState } from 'hooks'
import Images from 'assets'
import RegenerateDialog, { H5RegenerateDialog } from 'components/modal/RegenerateDialog'
import EditNoteDialog from 'components/modal/EditNoteDialog'
import { generateSmartNote, updateConsultation } from 'apis'
import SnackBar from '../snackBar'
import classnames from 'classnames'

interface Props {
  smartNote?: string
  cId?: string
  noteTemplate?: string
  onRegenerateSuccess?: (smartNote: string, noteTemplate: string) => void
  isMobile?: boolean
}

const SmartNote: React.FC<Props> = ({ smartNote, cId, onRegenerateSuccess, noteTemplate, isMobile }) => {
  const dispatch = useAppDispatch()

  const [data, setData] = useSetState({
    NoteTemplate: noteTemplate,
    ShowDialog: false,
    Generating: false,
    openEdit: false,
  })
  const { NoteTemplate, ShowDialog, Generating, openEdit } = data || {}
  const onClose = () => {
    setData({ ShowDialog: false })
  }

  const onRegenerateDialog = () => {
    setData({ ShowDialog: true })
  }
  const onRegenerate = (NoteTemplate: string) => {
    setData({ Generating: true })
    generateSmartNote<{ Consultation: IConsultation }>({ ConsultationId: cId, NoteTemplate })
      .then((res) => {
        dispatch(actions.updateNoteTemplate(NoteTemplate))
        setData({ Generating: false, NoteTemplate })
        onRegenerateSuccess?.(res.Consultation?.SmartNoteText, NoteTemplate)
        SnackBar({ msg: 'Successfully Regenerate', type: 'success' })
      })
      .finally(() => {
        setData({ Generating: false })
      })
  }
  const onEdit = (SmartNoteText: string) => {
    setData({ Generating: true })
    updateConsultation<IConsultation>({ ConsultationId: cId, SmartNoteText })
      .then((res) => {
        onRegenerateSuccess?.(res?.SmartNoteText, NoteTemplate || '')
        SnackBar({ msg: 'Successfully Edit', type: 'success' })
      })
      .finally(() => {
        setData({ openEdit: false, Generating: false })
      })
  }

  if (!smartNote) {
    return (
      <Box className={classnames(classes.container, classes.emptyContainer)}>
        <Box className={classes.emptyContent}>
          <img
            src={Images.empty}
            className={classnames(classes.emptyIcon, {
              [classes.h5EmptyIcon]: isMobile,
            })}
          />
          <Typography
            className={classnames(classes.emptyTitle, {
              [classes.h5EmptyTitle]: isMobile,
            })}
          >
            No content for the time being
          </Typography>
          <Typography
            className={classnames(classes.emptyDesc, {
              [classes.h5EmptyDesc]: isMobile,
            })}
          >
            Your encounter conversation must be at least 2 minutes long to generate a note.
          </Typography>
        </Box>
      </Box>
    )
  }
  return (
    <Box className={classes.container}>
      {ShowDialog ? (
        isMobile ? (
          <H5RegenerateDialog onClose={onClose} noteTemplate={NoteTemplate} onRegenerate={onRegenerate} />
        ) : (
          <RegenerateDialog onClose={onClose} noteTemplate={NoteTemplate} onRegenerate={onRegenerate} />
        )
      ) : null}
      {openEdit && (
        <EditNoteDialog htmlContent={smartNote} onClose={() => setData({ openEdit: false })} onEdit={onEdit} />
      )}
      <Box className={classnames(classes.wrap, { [classes.h5wrap]: isMobile })}>
        <Typography
          className={classes.noteTip}
          sx={{ fontSize: isMobile ? '12px' : '18px' }}
          onClick={onRegenerateDialog}
        >{`Note generated with the “${NoteTemplate || 'N/A'}” template`}</Typography>
        {!isMobile && (
          <Button
            className={classnames(classes.regenerate)}
            sx={{ marginRight: '16px' }}
            disabled={Generating}
            onClick={() => setData({ openEdit: true })}
          >
            Edit Note
            <img src={Images.editTheme} className={classnames(classes.refresh, {})} alt="" />
          </Button>
        )}
        <Button
          className={classnames(classes.regenerate, { [classes.h5regenerate]: isMobile })}
          disabled={Generating}
          onClick={onRegenerateDialog}
        >
          {isMobile ? '' : 'Regenerate Note'}
          <img
            src={Images.refresh}
            className={classnames(classes.refresh, {
              [classes.refreshLoading]: Generating,
              [classes.h5refresh]: isMobile,
            })}
            alt=""
          />
        </Button>
      </Box>
      <div id="markdown">
        {/* <Markdown
            className={classnames(classes.text, {
              [classes.h5text]: isMobile,
            })}
          >
            {smartNote}
          </Markdown> */}
        <div
          className={classnames(classes.text, {
            [classes.h5text]: isMobile,
          })}
          dangerouslySetInnerHTML={{ __html: smartNote }}
        />
      </div>
    </Box>
  )
}

export default SmartNote
