import Config from 'constants/Config'
import { S3 } from 'aws-sdk'
import SnackBar from 'components/snackBar'

const s3 = new S3({
  accessKeyId: Config.s3AccessKeyId,
  secretAccessKey: Config.s3SecretAccessKey,
  region: Config.awsRegion,
})

const s3Public = new S3({
  accessKeyId: Config.s3PublicAccessKeyId,
  secretAccessKey: Config.s3PublicSecretAccessKey,
  region: Config.awsRegion,
})

export const uploadFile = (body: Blob | File, fileName: string, ContentType?: string): S3.ManagedUpload => {
  const s3upload = s3.upload({
    Bucket: Config.s3PrivateBucket,
    Key: fileName,
    Body: body,
    ContentType: ContentType,
  })
  return s3upload
}

export const uploadFileWithData = async (body: Blob | File, fileName: string): Promise<string> => {
  const data = await uploadFile(body, fileName, 'audio/wav').promise()
  return data.Location
}

export const uploadPublicFile = async (body: Blob | File, fileName: string, ContentType?: string): Promise<string> => {
  try {
    const data = await s3Public
      .upload({
        Bucket: Config.s3Bucket,
        Key: fileName,
        Body: body,
        ContentType: ContentType || body?.type,
      })
      .promise()

    return data.Location
  } catch (error) {
    SnackBar({ msg: error })
    console.error('Upload error:', error)
    return ''
  }
}

export default uploadFileWithData
