import { useEffect, useMemo, useRef } from 'react'
import { Box, Pagination, Typography } from '@mui/material'
import { useSetState } from 'hooks'
import classes from './HistoryList.module.less'
import Images from 'assets'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import PeriodSelect from 'components/common/PeriodSelect'
import SwitchTab from 'components/common/SwitchTab'
import { formatTime, PERIOD_OPTIONS } from 'utils/common'
import { getConsultationOverview, getUserConsultationHistory, getConsultationFilter } from 'apis'
import { ArrowOutward, SouthEast } from '@mui/icons-material'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import Echarts, { ECOption } from 'components/Echarts'
import PopupFilter, { FilterDataOptions } from 'components/common/PopupFilter'

const PAGE_SIZE = 50

const TABS = [
  {
    label: 'Analytics',
  },
  {
    label: 'Consultations',
  },
]

const Summary = {
  ConsultationCount: {
    label: 'Consultations',
    icon: Images.consultations,
    number: 0,
    percent: 0,
  },
  DurationSum: {
    label: 'Total Hours',
    icon: Images.hours,
    number: 0,
    percent: 0,
  },
  HourSaved: {
    label: 'Hours Saved',
    icon: Images.hoursSaved,
    number: 0,
    percent: 0,
  },
  AverageLength: {
    label: 'Average Length',
    icon: Images.average,
    number: 0,
    percent: 0,
  },
}
export const DurationText = {
  FifteenCount: '0~15 min',
  ThirtyCount: '15~30 min',
  SixtyCount: '30~60 min',
  HourMoreCount: '>1 hour',
}
export const Color = ['#5787EF', '#14C9C9', '#F59F51', '#7B6BF1']

const columns: GridColDef[] = [
  {
    field: 'CreatedDate',
    headerName: 'Date',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 220,
    valueGetter: ({ row }) => format(new Date(row.CreatedDate || null), 'MM/dd/yyyy HH:mm a'),
  },
  {
    field: 'Title',
    headerName: 'Title',
    minWidth: 300,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Diseases',
    headerName: 'Diseases/Conditions',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 300,
    cellClassName: classes.cellDiseases,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.Keywords?.Disease?.map((v: string) => (
            <span key={v} className={classes.chip}>
              {v}
            </span>
          ))}
        </>
      )
    },
  },
  {
    field: 'Medicine',
    headerName: 'Medications/Procedures',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 300,
    cellClassName: classes.cellDiseases,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.Keywords?.Medicine?.map((v: string) => (
            <span key={v} className={classes.chip}>
              {v}
            </span>
          ))}
        </>
      )
    },
  },
  {
    field: 'Duration',
    headerName: 'Duration',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 120,
    valueGetter: ({ row }) => formatTime(row.Duration),
  },
  {
    field: 'NoteTemplate',
    headerName: 'Note Template',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 280,
  },
]

const HistoryList = () => {
  const navigate = useNavigate()

  const [state, setState] = useSetState({
    page: 0,
    rowCount: 0,
    period: PERIOD_OPTIONS.find((item) => item.value === '7-Day'),
    render: false,
    rows: [] as Record<string, any>[],
    loading: true,
    tabIdx: 0,
    DurationBucket: {} as Record<string, { Percent: number; Rate: number }>,
    DiseaseCount: [] as Record<string, any>[],
    MedicineCount: [] as Record<string, any>[],
    filterDataOptions: {} as FilterDataOptions,
    reGetList: false,
  })
  const { page, period, rows, loading, rowCount, tabIdx, DurationBucket, DiseaseCount, MedicineCount } = state
  const { filterDataOptions, reGetList } = state
  const dataRef = useRef({ filterData: {} as Record<string, any[]> })
  const { filterData } = dataRef.current
  const tu = period?.value

  const { Duration, ecOption } = useMemo(() => {
    const Duration = Object.entries(DurationBucket)
      .map(([key, item]) => {
        return {
          //@ts-ignore
          label: DurationText[key],
          percent: item.Percent,
          rate: item.Rate,
        }
      })
      .sort((a, b) => b.percent - a.percent)

    const ecOption = {
      color: Color,
      series: [
        {
          type: 'pie',
          radius: [0, '95%'],
          center: ['50%', '50%'],
          roseType: 'radius', //radius
          itemStyle: {
            borderRadius: 5,
            borderWidth: 2,
            borderColor: '#fff',
          },
          label: {
            show: false,
          },
          data: Duration.map((item, i) => ({
            value: item.percent,
            name: item.label,
            itemStyle:
              i === 0
                ? { radius: ['0%', '100%'] }
                : { radius: ['0%', `${(item.percent / (Duration[0].percent || 1)) * 100}%`] },
          })),
        },
      ],
    } as ECOption
    return { Duration, ecOption }
  }, [DurationBucket])

  useEffect(() => {
    if (tabIdx === 0) {
      getConsultationOverview({ tu }).then((res: any) => {
        Summary.ConsultationCount.number = res.ConsultationCount
        Summary.ConsultationCount.percent = res.ConsultationGrowthRate
        Summary.DurationSum.number = res.DurationSum
        Summary.DurationSum.percent = res.DurationGrowthRate
        Summary.HourSaved.number = res.HourSaved || 0
        Summary.HourSaved.percent = res.HourSavedGrowthRate
        Summary.AverageLength.number = res.AverageLength || 0
        Summary.AverageLength.percent = res.AverageLengthGrowthRate
        setState((pre) => ({
          ...pre,
          ...res,
          render: !pre.render,
        }))
      })
      return
    }

    getConsultationFilter({ tu }).then((res: any) => {
      const filterDataOptions = res || {}
      Object.entries(filterDataOptions).forEach(([key, item]) => {
        const options = item as string[]
        filterDataOptions[key] = {
          name: { Diseases: 'diseases', Medicines: 'medicines' }[key] || key,
          label: key,
          options: options.map((v) => ({ label: v, value: v })),
        }
      })
      filterDataOptions['tu'] = {
        name: 'tu',
        label: 'Date Range',
        options: PERIOD_OPTIONS,
      }
      setState({
        filterDataOptions,
      })
    })
  }, [tu, tabIdx])

  useEffect(() => {
    if (tabIdx === 0) return
    const filterParams: any = {}
    Object.entries(dataRef.current.filterData).forEach(([key, item]) => {
      if (key === 'search') {
        filterParams.title = item[0]?.value || ''
        return
      }
      filterParams[key] = JSON.stringify(item?.map((v) => v.value))
    })
    setState({ loading: true })
    getUserConsultationHistory({ skip: page * PAGE_SIZE, take: PAGE_SIZE, ...filterParams, tu })
      .then((res: any) => {
        const rows = res?.Consultations || []
        setState((pre) => ({ ...pre, rows, rowCount: res?.Total }))
      })
      .finally(() => {
        setState({ loading: false })
      })
  }, [tu, page, setState, tabIdx, reGetList])

  return (
    <Box className={classes.layout}>
      <Box className={classes.header}>
        <span className={classes.headerText}>Consultations Dashboard</span>
        <SwitchTab
          tabs={TABS}
          onChange={(tabIdx) => {
            setState({ tabIdx })
            if (tabIdx === 1) {
              setState({ period: PERIOD_OPTIONS.find((item) => item.value === 'ALL') })
            }
          }}
          value={tabIdx}
        />
      </Box>

      <Box className={classes.body} style={{ display: tabIdx === 0 ? 'block' : 'none' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
          <Typography className={classes.tableTitle}>Overview</Typography>
          <PeriodSelect
            period={period}
            onChange={(value) => {
              setState({ period: value, page: 0 })
            }}
            label=""
            className={classes.inputRoot}
            rootClass={classes.rootClass}
          />
        </Box>

        <Box className={classes.summary}>
          {Object.entries(Summary).map(([key, item], index) => {
            const color = item.percent >= 0 ? '#2AA33A' : '#D3543A'
            return (
              <Box key={index} className={classes.summaryItem}>
                <Box>
                  <Typography className={classes.leftText}>{item.label}</Typography>
                  <Typography className={classes.number}>{item.number?.toLocaleString('en-US')}</Typography>
                  <Typography className={classes.percent} sx={{ color }}>
                    {item.percent >= 0 ? '+' : '-'}
                    <span style={{ margin: '0 4px' }}>{Math.abs(item.percent)}%</span>
                    {item.percent >= 0 ? <ArrowOutward /> : <SouthEast />}
                  </Typography>
                </Box>
                <img src={item.icon} alt="logo" />
              </Box>
            )
          })}
        </Box>

        <Box className={classes.chart}>
          <Box className={classes.chartItem}>
            <Typography className={classes.tableTitle}>Duration of Consultation</Typography>
            <div style={{ borderTop: '1px solid #E5E5F5', margin: '24px 0' }} />
            {Duration.length > 0 && (
              <Echarts width={240} height={240} id="pie" option={ecOption} style={{ margin: '0 auto' }} />
            )}
            {Duration.map((item, index) => {
              const color = item.rate >= 0 ? '#2AA33A' : '#D3543A'
              const background = item.rate >= 0 ? '#EEF9F8' : '#F9ECEA'

              return (
                <Box key={index} className={classes.durationItem}>
                  <span style={{ width: 12, height: 12, borderRadius: '50%', background: Color[index] }} />
                  <Typography sx={{ color: '#595959' }}>{item.label}</Typography>
                  <span style={{ borderTop: '1px dashed #c2c2c2', flex: 1 }} />
                  <Typography sx={{ fontWeight: '600' }}>{Math.abs(item.percent)}%</Typography>
                  <Typography className={classes.durationPercent} sx={{ background, color }}>
                    {item.rate >= 0 ? '+' : '-'}
                    <span style={{ margin: '0 4px' }}>{Math.abs(item.percent)}%</span>
                    {item.rate >= 0 ? (
                      <ArrowOutward sx={{ fontSize: '14px' }} />
                    ) : (
                      <SouthEast sx={{ fontSize: '14px' }} />
                    )}
                  </Typography>
                </Box>
              )
            })}
            {Duration.length === 0 && <Box sx={{ margin: '100px 0', textAlign: 'center' }}>No Data</Box>}
          </Box>

          <Box className={classes.chartItem}>
            <Typography className={classes.tableTitle}>Disease</Typography>
            <div style={{ borderTop: '1px solid #E5E5F5', margin: '24px 0' }} />
            {DiseaseCount.slice(0, 10).map((item, index) => {
              const denominator = DiseaseCount[0]?.Count || 1
              const width = (item.Count / denominator) * 100
              return (
                <Box
                  key={index}
                  className={classes.durationItem}
                  onClick={() => {
                    dataRef.current.filterData = {
                      diseases: [{ label: item.Name, value: item.Name }],
                      tu: [{ label: PERIOD_OPTIONS.find((item) => item.value === tu)?.label, value: tu }],
                    }
                    setState({ tabIdx: 1 })
                  }}
                >
                  <Typography className={classes.diseaseName}>{item.Name}</Typography>
                  <Box sx={{ flex: 1, height: '16px', borderRadius: '8px', background: '#F5F5F5' }}>
                    <Box sx={{ width: `${width}%`, height: '16px', borderRadius: '8px', background: '#165DFF' }} />
                  </Box>
                  <Typography sx={{ fontWeight: '600', minWidth: '32px', textAlign: 'center' }}>
                    {item.Count}
                  </Typography>
                </Box>
              )
            })}
            {DiseaseCount.length === 0 && <Box sx={{ margin: '100px 0', textAlign: 'center' }}>No Data</Box>}
          </Box>

          <Box className={classes.chartItem}>
            <Typography className={classes.tableTitle}>Treatment</Typography>
            <div style={{ borderTop: '1px solid #E5E5F5', margin: '24px 0' }} />
            {MedicineCount.slice(0, 10).map((item, index) => {
              const { Name } = item
              const denominator = MedicineCount[0]?.Count || 1
              const width = (item.Count / denominator) * 100
              return (
                <Box
                  key={index}
                  className={classes.durationItem}
                  onClick={() => {
                    dataRef.current.filterData = {
                      medicines: [{ label: Name, value: Name }],
                      tu: [{ label: PERIOD_OPTIONS.find((item) => item.value === tu)?.label, value: tu }],
                    }
                    setState({ tabIdx: 1 })
                  }}
                >
                  <Typography className={classes.diseaseName}>{item.Name}</Typography>
                  <Box sx={{ flex: 1, height: '16px', borderRadius: '8px', background: '#F5F5F5' }}>
                    <Box sx={{ width: `${width}%`, height: '16px', borderRadius: '8px', background: '#165DFF' }} />
                  </Box>
                  <Typography sx={{ fontWeight: '600', minWidth: '32px', textAlign: 'center' }}>
                    {item.Count}
                  </Typography>
                </Box>
              )
            })}
            {MedicineCount.length === 0 && <Box sx={{ margin: '100px 0', textAlign: 'center' }}>No Data</Box>}
          </Box>
        </Box>
      </Box>

      <Box className={classes.body} style={{ display: tabIdx === 1 ? 'block' : 'none' }}>
        <Box className={classes.table}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography className={classes.tableTitle}>Table of Consultations</Typography>
            <PopupFilter
              filterDataOptions={filterDataOptions as FilterDataOptions}
              filterData={filterData}
              setFilters={(value) => {
                dataRef.current.filterData = value
                const newTu = value?.tu?.[0]?.value || 'ALL'
                if (newTu !== tu) {
                  setState({ period: PERIOD_OPTIONS.find((item) => item.value === newTu) })
                }
                setState({ page: 0, reGetList: !reGetList })
              }}
            />
          </Box>
          <Box style={{ overflowX: 'auto', marginTop: '24px' }}>
            <DataGrid
              rows={rows}
              rowCount={rowCount}
              columns={columns}
              rowSelection={false}
              getRowHeight={() => 'auto'}
              localeText={{ noRowsLabel: 'No Data' }}
              classes={{
                virtualScroller: classes.virtualScroller,
                row: classes.row,
                main: classes.main,
                cell: classes.cell,
                columnHeader: classes.columnHeader,
                columnSeparator: classes.columnSeparator,
              }}
              onRowClick={({ row }) => {
                navigate(`/history/${row.ConsultationId}`, {
                  replace: false,
                  state: { showBack: true },
                })
              }}
              slots={{
                pagination: () => {
                  return (
                    <Pagination
                      className={classes.pagination}
                      color="primary"
                      count={Math.ceil(rowCount / PAGE_SIZE)}
                      page={page + 1}
                      showFirstButton
                      showLastButton
                      onChange={(event, page) => {
                        setState({ page: page - 1 })
                      }}
                    />
                  )
                },
              }}
              loading={loading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default HistoryList
