import React, { useState } from 'react'
import { Box, Button, Dialog as DialogUi, Typography } from '@mui/material'
import classes from './EditNoteDialog.module.less'
import RichText from 'components/common/RichText'

interface Props {
  onClose: () => void
  htmlContent?: string
  onEdit?: (template: string) => void
}

const EditNoteDialog: React.FC<Props> = ({ onClose, htmlContent, onEdit }) => {
  const [data, setData] = useState(htmlContent || '')
  const [open, setOpen] = useState(true)

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onRegenerateCall = () => {
    onEdit?.(data as string)
  }

  const onCancel = () => {
    removeDialog()
  }

  return (
    <DialogUi open={open} classes={{ paper: classes.container }}>
      <Typography className={classes.title}>Edit Note</Typography>

      <RichText
        onSubmit={(html) => {
          setData(html)
        }}
        htmlContent={data}
        className={classes.editor}
        editorStyle={{ flex: 1, overflowY: 'auto' }}
      />

      <Box className={classes.bottom}>
        <Button variant="outlined" onClick={onCancel} color="primary" className={classes.btn}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onRegenerateCall} color="primary" className={classes.btn} disabled={!data}>
          Save
        </Button>
      </Box>
    </DialogUi>
  )
}

export default EditNoteDialog
