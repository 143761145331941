import { createTheme, keyframes } from '@mui/material/styles'
// button按钮 全局防抖
const debounce = keyframes({
  from: {
    pointerEvents: 'none',
  },
  to: {
    pointerEvents: 'auto',
  },
})

export const theme = createTheme({
  typography: {
    // fontFamily: 'Helvetica Neue, sans-serif',
  },
  palette: {
    primary: {
      main: '#3333F5',
    },
    // text: {
    //   primary: '#333333'
    // },
    divider: '#EFEFEF',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          //   width: 6,
          // },
          // '::-webkit-scrollbar-track': {
          //   backgroundColor: 'transparent',
          // },
          // '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          //   borderRadius: 3,
          //   backgroundColor: '#888',
          //   minHeight: 24,
          // },
          // '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
          //   backgroundColor: '#888',
          // },
          // '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
          //   backgroundColor: '#888',
          // },
          // '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
          //   backgroundColor: '#888',
          //   display: 'block',
          // },
          // '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
          //   backgroundColor: '#888',
          // },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          animation: `${debounce} 1s 1ms step-end forwards`,
          '&:active': {
            animation: 'none',
          },
        },
      },
    },
  },
})
