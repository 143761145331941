import { useEffect } from 'react'
import { Box, Typography, Button, TextField } from '@mui/material'
import { GppGood } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import useSetState from 'hooks/useSetState'
import { isValidPassword, isValidEmail } from 'utils/common'
import Images from 'assets'
import { loginByPassword, verifyToken } from 'apis'
import { actions } from 'app/profileSlice'
import Storages from 'utils/Storages'
import { useAppDispatch } from 'app/hooks'
import styles from './SignUp.module.less'
import Config from 'constants/Config'

export default function Login() {
  const dispatch = useAppDispatch()
  const [state, setState] = useSetState({
    Email: '',
    Password: '',
    hasError: false,
    step: 0,
    Token: '',
    UserId: '',
  })
  const { Email, Password, hasError, step, Token, UserId } = state
  const navigate = useNavigate()
  const onLogin = async () => {
    let hasError = false
    if (!isValidEmail(Email)) {
      hasError = true
    }
    setState({ hasError })
    if (hasError) return
    loginByPassword({ UserName: Email, Password, VisitorType: 'PCS' })
      .then((res: any) => {
        if (res?.MFAEnabled) {
          setState({ step: 1, UserId: res.UserId })
          return
        }
        Storages.setAuthToken(res.UserToken)
        dispatch(actions.loginSuccess(res))
        navigate('/', { replace: true })
      })
      .catch(() => {})
  }

  const onVerifyToken = () => {
    verifyToken({ Token, UserId }).then((res: any) => {
      Storages.setAuthToken(res.UserToken)
      dispatch(actions.loginSuccess(res))
      navigate('/', { replace: true })
    })
  }

  useEffect(() => {
    const accessToken = Storages.getAuthToken()
    if (accessToken) {
      navigate('/', { replace: true })
    }
  }, [navigate])

  return (
    <Box className={styles.stepContainer}>
      <Typography className={styles.header} sx={{ marginBottom: '60px' }}>
        {Config.appName}
      </Typography>

      {step === 0 && (
        <Box className={styles.card}>
          <img src={Config.logo} alt="logo" className={styles.logo} />
          <Typography className={styles.cardTitle} style={{ marginBottom: '16px' }}>
            Welcome!
          </Typography>
          <Typography className={styles.text} sx={{ textAlign: 'center', marginBottom: '48px' }}>
            Welcome Please enter your email username <br /> and password below
          </Typography>
          <Typography className={styles.label}>Email</Typography>
          <TextField
            value={Email}
            variant="outlined"
            placeholder="Email"
            fullWidth
            onChange={(e) => setState({ Email: e.target.value })}
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            error={(hasError && !isValidEmail(Email)) || false}
            helperText={hasError && !isValidEmail(Email) && 'Please enter a valid email'}
          />

          <Typography className={styles.label}>Password</Typography>
          <TextField
            variant="outlined"
            placeholder="Password"
            fullWidth
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            type="password"
            onChange={(e) => setState({ Password: e.target.value })}
            error={(hasError && !isValidPassword(Password).checked) || false}
            helperText={hasError && !isValidPassword(Password).checked && isValidPassword(Password).message}
          />

          <Button
            variant="contained"
            className={`${styles.button} ${styles.next}`}
            onClick={() => onLogin()}
            disabled={!Email || !Password}
            classes={{ disabled: styles.disabled }}
          >
            Sign In
          </Button>

          <Typography
            className={styles.signIn}
            sx={{ textAlign: 'center', marginTop: '16px' }}
            onClick={() => navigate('/resetPassword', { state: { Email } })}
          >
            Forgot Password?
          </Typography>
        </Box>
      )}
      {step === 1 && (
        <Box className={styles.card}>
          <Box className={styles.safeLogo}>
            <GppGood htmlColor="#333" sx={{ fontSize: '40px' }} />
          </Box>
          <Typography className={styles.cardTitle} style={{ marginBottom: '16px' }}>
            Multi-factor authentication
          </Typography>
          <Typography className={styles.text} sx={{ textAlign: 'center', marginBottom: '48px' }}>
            Your account is secured using multi-factor authentication （MFA）. To finish signing in, turn On Or view
            your MFA device and type the authentication code below.
          </Typography>

          <TextField
            value={Token}
            variant="outlined"
            placeholder=""
            fullWidth
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            onChange={(e) => setState({ Token: e.target.value })}
          />

          <Button
            variant="contained"
            className={`${styles.button} ${styles.next}`}
            onClick={() => onVerifyToken()}
            disabled={!Token}
            classes={{ disabled: styles.disabled }}
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
  )
}
