import { useQueue } from 'hooks/useQueue'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LiveClient, LiveTranscriptionEvents, createClient, LiveConnectionState } from '@deepgram/sdk'
import { format } from 'date-fns'
import { Box, Button, CircularProgress, Typography, Dialog, IconButton } from '@mui/material'
import { Tooltip, ClickAwayListener } from '@mui/material'
import { Close, ArrowBackIos } from '@mui/icons-material'
import Images from 'assets'
import { IConsultation, ITranscription } from 'types/transcription'
import TranscriptionItem from 'components/transcript/TranscriptionItem'
import {
  updateConsultation,
  getConsultationDetail,
  startConsultation as startConsultationApi,
  submitConsultation,
  submitPatientConsent,
} from 'apis'
import { uploadFileWithData } from 'utils/uploadFile'
import SwitchTab from 'components/common/SwitchTab'
import useSetState from 'hooks/useSetState'
import Config from 'constants/Config'
import classes from './Transcript.module.less'
import { copyElementContent } from 'utils/common'
import { useParams, useBlocker, useLocation, useNavigate } from 'react-router-dom'
import SnackBar from 'components/snackBar'
import AutoTitleTextField from 'components/common/AutoTitleTextField'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { updateTitle } from 'app/transcriptSlice'
import { actions, selectProfile } from 'app/profileSlice'
import SmartNote from 'components/transcript/SmartNote'
import FeedbackDialog from 'components/modal/FeedbackDialog'
import PatientTipDialog from 'components/modal/PatientTipDialog'
import LabelItem from '../components/common/LabelItem'
import CustomSelect, { CustomSelectChoice } from '../components/common/CustomSelect'
import { LANGUAGE_ENUM } from '../constants/common'
import SwitchLanguageDialog from '../components/modal/SwitchLanguageDialog'

const deepgram = createClient('a56c4a3056c8276dc454fd7992f71c4fd97c8f28')

const TABS = [
  {
    label: 'Transcript',
  },
  {
    label: 'Note',
  },
]

enum STATUS {
  Init = 'Init',
  Transcript = 'Transcript',
  Generating = 'Generating',
  GeneratingSuccess = 'GeneratingSuccess',
}
export enum PATIENT_CONSENT {
  Agreed = 'Agreed',
  Disagreed = 'Disagreed',
  AutoAgreed = 'AutoAgreed',
}

let keepAlive: string | number | NodeJS.Timeout | undefined
const Transcript = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const profile = useAppSelector(selectProfile)
  const { add, remove, first, size, queue, clear } = useQueue<any>([])
  const { id } = useParams<Record<string, string>>()
  const location = useLocation()
  const { showBack } = useMemo(() => location.state || {}, [location.state])

  const isHistory = !!id
  const user = profile.user
  const refStream = useRef<Array<Blob>>([])
  const containerRef = useRef<HTMLAudioElement>(null)
  const workerRef = useRef<Worker | null>(null)
  const dataRef = useRef({ status: '' as STATUS, userMedia: null as unknown as MediaStream })
  const [transcription, setTranscription] = useState<{
    transcriptions: Array<ITranscription>
    transcribing?: ITranscription
    updateMsg?: string
  }>({ transcriptions: [] })
  const { transcriptions, transcribing, updateMsg } = transcription
  const [connection, setConnection] = useState<LiveClient | null>()
  const [isListening, setListening] = useState(false)
  const [isProcessing, setProcessing] = useState(false)
  const [micOpen, setMicOpen] = useState(false)
  const [language, setLanguage] = useState(user?.Language || 'en')
  const [microphone, setMicrophone] = useState<MediaRecorder | null>()
  const retryRef = useRef<{
    count: number
    title?: string
    fileName?: string
    hasUploadFile?: boolean
    close?: boolean
    isResume: boolean // 继续一个新的并连接到当前的对话
    resumeTranscription: Array<ITranscription>
    stop?: boolean
    startTime: number
    endTime: number
    patientConsent: PATIENT_CONSENT | string
  }>({ count: 0, isResume: false, resumeTranscription: [], stop: false, startTime: 0, endTime: 0, patientConsent: '' })
  const [data, setData] = useSetState({
    index: 0,
    showTab: false,
    title: '',
    cId: id,
    submitFailed: false,
    status: STATUS.Init,
    smartNoteText: '',
    noteTemplate: '',
    backendModal: false,
    feedbackModal: false,
    showPatientTip: false,
    showSwitchLanguage: false,
  })
  const { index, showTab, status, title, cId, smartNoteText, noteTemplate, backendModal, feedbackModal } = data
  const { showPatientTip, showSwitchLanguage } = data
  const isRecord = !!microphone && micOpen && isListening
  const showResume = false //index === 0 && ![STATUS.Transcript, STATUS.Generating].includes(status)
  const blocker = useBlocker(() => [STATUS.Transcript, STATUS.Generating].includes(status))
  const uploadToServer = useCallback(async () => {
    const id = user?.pcId
    const { isResume, resumeTranscription, startTime, endTime, patientConsent } = retryRef.current
    setData({ status: patientConsent === PATIENT_CONSENT.Disagreed ? STATUS.Init : STATUS.Generating })
    let createByBackend = false
    const time = endTime - startTime
    if (!isResume && time > 2 * 60 * 1000 && transcriptions.length < 10) {
      createByBackend = true
    }
    const fileName = retryRef.current.fileName || `consultation/${id}/${createByBackend ? 'p' : ''}${Date.now()}.wav`

    try {
      const blob = new Blob(refStream.current, { type: 'audio/ogg; codecs=opus' })
      !retryRef.current.hasUploadFile && (await uploadFileWithData(blob, fileName))
      retryRef.current.hasUploadFile = true
      const AudioUrl = Config.s3PrivateUrl + '/' + fileName
      if (patientConsent === PATIENT_CONSENT.Disagreed) {
        submitPatientConsent({ PatientConsent: patientConsent, ConsultationId: cId, AudioUrl }).then(() => {
          refStream.current = []
          retryRef.current.count = 0
          retryRef.current.hasUploadFile = false
          retryRef.current.fileName = ''
          retryRef.current.isResume = false
          retryRef.current.resumeTranscription = []
        })
        return
      }

      let res = {} as IConsultation
      if (isResume) {
        res = await updateConsultation({ ConsultationId: cId, Title: title, AudioUrl, Transcript: resumeTranscription })
        dispatch(
          updateTitle({
            pcId: cId as string,
            Title: title,
          }),
        )
      } else {
        res = await submitConsultation<IConsultation>({
          Transcript: createByBackend ? [] : transcriptions,
          Title: title,
          AudioUrl,
          Source: 'Desktop',
          ConsultationId: cId,
          NeedResolveAudio: createByBackend,
          StartTime: startTime,
        })
      }

      setData({
        status: STATUS.GeneratingSuccess,
        showTab: true,
        index: 1,
        smartNoteText: res.SmartNoteText || '',
        noteTemplate: res.NoteTemplate,
        cId: res.pcId,
        backendModal: createByBackend,
      })
      refStream.current = []
      retryRef.current.count = 0
      retryRef.current.hasUploadFile = false
      retryRef.current.fileName = ''
      retryRef.current.isResume = false
      retryRef.current.resumeTranscription = []
    } catch (e) {
      if (retryRef.current.count >= 4) return
      retryRef.current.fileName = fileName
      SnackBar({ msg: 'Retrying...' })
      retryRef.current.count = retryRef.current.count + 1
      setTimeout(() => {
        uploadToServer()
      }, 200)
    }
  }, [user?.pcId, setData, transcriptions, title, cId])
  const startListening = useCallback(
    (lan: string, callback: (() => void) | null) => {
      console.log('connecting to deepgram')
      let isExecCallback = false
      const connectionInder = deepgram.listen.live({
        model: 'nova-2',
        language: lan || user?.Language || 'en',
        interim_results: true,
        // smart_format: true,
        numerals: true,
        punctuate: true,
        utterances: true,
        utt_split: 1.0,
        diarize: true,
      })

      connectionInder.on(LiveTranscriptionEvents.Open, () => {
        console.log('connection established')
        if (keepAlive) clearInterval(keepAlive)
        keepAlive = setInterval(() => {
          console.log('KeepAlive sent.')
          connectionInder.keepAlive()
        }, 3000)
        callback?.()
        isExecCallback = true
        if (retryRef.current.close) {
          retryRef.current.close = false
          setTimeout(() => {
            setListening(true)
          }, 500)
        } else {
          setListening(true)
        }

        connectionInder.on(LiveTranscriptionEvents.Transcript, (data) => {
          const speaker = data.channel.alternatives[0].words?.[0]?.speaker
          const transcript = data.channel.alternatives[0]?.transcript
          console.log('Transcript', transcript, data.channel)
          if (data.is_final && transcript !== '') {
            const newItem = {
              Duration: data.duration,
              Start: data.start,
              Caption: transcript,
              Speaker: speaker,
              Time: new Date().getTime(),
            } as ITranscription
            setTranscription((prevState) => {
              const preTranscriptions = [...(prevState.transcriptions || [])]
              preTranscriptions.push(newItem)
              if (retryRef.current.isResume) {
                retryRef.current.resumeTranscription.push(newItem)
              }
              return {
                transcribing: {
                  Duration: 0,
                  Start: 0,
                  Caption: '',
                  Time: new Date().getTime(),
                },
                updateMsg: Math.random() + '',
                transcriptions: preTranscriptions,
              }
            })
          } else {
            setTranscription((prevState) => {
              const newItem = prevState.transcribing || {
                Duration: data.duration,
                Start: data.start,
                Caption: transcript,
                Time: new Date().getTime(),
              }
              newItem.Caption = transcript
              return {
                ...prevState,
                updateMsg: Math.random() + '',
                transcribing: newItem,
              }
            })
          }
        })
      })

      connectionInder.on(LiveTranscriptionEvents.Close, (...data) => {
        console.log('connection closed', data)
        retryRef.current.close = true
        if (keepAlive) clearInterval(keepAlive)
        // connectionInder.removeAllListeners()
        startListening(lan, isExecCallback ? null : callback)
      })

      connectionInder.on(LiveTranscriptionEvents.Error, (...err) => {
        console.log('connection Error', err)
      })
      connectionInder.on(LiveTranscriptionEvents.Metadata, (...data) => {
        console.log('connection Metadata', data)
      })
      connectionInder.on(LiveTranscriptionEvents.SpeechStarted, (...data) => {
        console.log('connection SpeechStarted', data)
      })
      connectionInder.on(LiveTranscriptionEvents.UtteranceEnd, (...data) => {
        console.log('connection UtteranceEnd', data)
      })
      connectionInder.on(LiveTranscriptionEvents.Warning, (...data) => {
        console.log('connection Warning', data)
      })
      setConnection(connectionInder)
      return connectionInder
    },
    [user?.Language],
  )
  const startConsultation = useCallback(
    ({ isResume = false, lan }: { isResume?: boolean; lan?: string } = {}) => {
      retryRef.current.isResume = isResume
      retryRef.current.startTime = new Date().getTime()
      const title = `Consultation ${format(retryRef.current.startTime, 'yyyy-MM-dd')}`
      setData({ showPatientTip: true })
      if (isResume) {
        retryRef.current.resumeTranscription = []
        clear()
      } else {
        const params = { Title: title, Source: 'Desktop' }
        lan && Object.assign(params, { Language: lan })
        startConsultationApi(params).then((res: any) => {
          lan && user?.Language !== lan && dispatch(actions.updateUser({ Language: lan }))
          setData({ cId: res.pcId })
        })
      }
      setData((pre) => ({
        ...pre,
        title: isResume ? pre.title : title,
        showTab: false,
        smartNoteText: isResume ? pre.smartNoteText : '',
        index: 0,
      }))
      setTranscription((prevState) => {
        const newItem = {
          Duration: 0,
          Start: 0,
          Caption: '',
          Time: new Date().getTime(),
        }
        return {
          transcriptions: isResume ? prevState.transcriptions : [],
          transcribing: newItem,
        }
      })
      const connectionInder = startListening(lan || '', async () => {
        try {
          const userMedia = await navigator.mediaDevices.getUserMedia({
            audio: true,
          })
          dataRef.current.userMedia = userMedia
          const microphone = new MediaRecorder(userMedia)
          microphone.start(300)
          retryRef.current.stop = false
          microphone.onstart = () => {
            setData({
              status: STATUS.Transcript,
            })
            setMicOpen(true)
          }

          microphone.onstop = () => {
            userMedia.getTracks().forEach((track) => track.stop())
            setMicOpen(false)
          }

          microphone.ondataavailable = (e) => {
            if (retryRef.current.stop) return
            refStream.current?.push(e.data)
            add(e.data)
          }
          setMicrophone(microphone)
        } catch (e) {
          connectionInder.removeAllListeners()
          connectionInder.finish()
          SnackBar({ msg: 'Requires open microphone permission' })
        }
      })
    },
    [add, setData, startListening, clear, user?.Language],
  )

  const stopConsultation = useCallback(() => {
    if (STATUS.Generating === status) return
    if (!microphone) return
    setTranscription((prevState) => {
      return {
        ...prevState,
        transcribing: undefined,
      }
    })
    retryRef.current.stop = true
    retryRef.current.endTime = new Date().getTime()
    clear()
    setListening(false)
    microphone.stop()
    connection?.finish()
    if (keepAlive) clearInterval(keepAlive)
    connection?.removeAllListeners()
    uploadToServer()
    retryRef.current.resumeTranscription = []
  }, [status, connection, microphone, uploadToServer])
  const onRegenerateSuccess = (note: string, noteTemplate: string) => {
    setData({ smartNoteText: note, noteTemplate })
  }
  useEffect(() => {
    if (!id) return
    getConsultationDetail<IConsultation>({ cId: id }).then((item) => {
      setData({ smartNoteText: item.SmartNoteText, noteTemplate: item.NoteTemplate, title: item.Title, cId: item.pcId })
      setTranscription({ transcriptions: item.Transcript || [] })
    })
  }, [id, setData])

  const onTitleChange = (title: string) => {
    setData({ title })
  }
  const onUpdate = (title: string) => {
    setData({ title })
    if (cId) {
      dispatch(updateTitle({ pcId: cId, Title: title }))
      updateConsultation({ ConsultationId: cId, Title: title })
      retryRef.current.title = title
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [updateMsg])
  useEffect(() => {
    const processQueue = async () => {
      if (size > 0 && !isProcessing && first) {
        setProcessing(true)
        const blob = first
        if (isListening && blob) {
          const buffer = await blob.arrayBuffer()
          if (connection?.getReadyState() !== LiveConnectionState.OPEN) {
            console.log('please waitting....', connection?.getReadyState())
          } else {
            if (blob.size > 500) {
              connection?.send(buffer)
            } else {
              connection.keepAlive()
            }
            remove()
          }
        }

        // const waiting = setTimeout(() => {
        //   clearTimeout(waiting)
        //   setProcessing(false)
        // }, 200)
        workerRef.current?.postMessage('start')
        if (workerRef.current) {
          workerRef.current.onmessage = function (e) {
            if (e.data === 'done') {
              setProcessing(false)
            }
          }
        }
      }
    }

    processQueue()
  }, [connection, queue, remove, first, size, isProcessing, isListening])
  const onChangeTab = useCallback(
    (value: number) => {
      console.log('222222222', cId, status)
      if (status === STATUS.Generating || !cId) return
      setData({ index: value })
    },
    [status, smartNoteText, setData, cId],
  )
  const onChangeLanguage = useCallback(
    (value: CustomSelectChoice | null) => {
      value && setLanguage(value.value as string)
    },
    [setData],
  )
  const onClickCopy = useCallback(() => {
    copyElementContent('markdown')
  }, [])
  const onClosePatientTip = (patientConsent: PATIENT_CONSENT) => {
    retryRef.current.patientConsent = patientConsent
    setData({ showPatientTip: false })
    if (patientConsent === PATIENT_CONSENT.Disagreed) {
      stopConsultation()
      return
    }
    submitPatientConsent({ PatientConsent: patientConsent, ConsultationId: cId })
  }
  useEffect(() => {
    workerRef.current = new Worker(new URL('../worker/transcript.worker.ts', import.meta.url), { type: 'module' })
    return () => {
      workerRef.current?.terminate()
      dataRef.current?.userMedia?.getTracks().forEach((track) => track.stop())
    }
  }, [])
  useEffect(() => {
    dataRef.current.status = status
  }, [status])
  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      if ([STATUS.Transcript, STATUS.Generating].includes(dataRef.current.status)) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeunload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [])

  // isListening 正在转义....
  if (!isHistory && STATUS.Init === status) {
    return (
      <Box className={classes.container} style={{ backgroundColor: 'white' }}>
        <Box className={classes.welcome}>
          <Box className={classes.logoBox}>
            <img src={Config.logo} className={classes.logo} alt="logo" />
          </Box>
          <Typography className={classes.welcomeText}>Welcome to {Config.appName}!</Typography>
          <Typography className={classes.welcomeTip}>Get smart notes from your consultation in minutes</Typography>
          <LabelItem label="Switch Language">
            <CustomSelect
              label=""
              sx={{ width: '380px' }}
              sxSelect={{ height: 40 }}
              value={LANGUAGE_ENUM?.find((item1: CustomSelectChoice) => item1.value === language)}
              onChange={onChangeLanguage}
              choices={LANGUAGE_ENUM || []}
            />
          </LabelItem>
          <Button variant="contained" className={classes.start} onClick={() => startConsultation({ lan: language })}>
            Start Consultation
          </Button>
        </Box>
      </Box>
    )
  }
  return (
    <Box className={classes.container} style={{ backgroundColor: 'white' }}>
      <Box className={classes.header}>
        {showBack && (
          <IconButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowBackIos />
          </IconButton>
        )}
        <AutoTitleTextField value={title} onTitleChange={onTitleChange} onUpdate={onUpdate} />
        <SwitchTab tabs={TABS} onChange={onChangeTab} value={index} />
      </Box>
      <Box className={classes.content} ref={containerRef} id="transcriptions">
        {index === 0 ? (
          <>
            {transcriptions?.map((item) => {
              return <TranscriptionItem item={item} key={item.Time} />
            })}
            {transcribing && (
              <TranscriptionItem item={transcribing} key={transcribing?.Time} isTranscribing={isRecord} />
            )}
          </>
        ) : (
          <SmartNote
            smartNote={smartNoteText}
            noteTemplate={noteTemplate}
            key={cId}
            cId={cId}
            onRegenerateSuccess={onRegenerateSuccess}
          />
        )}
      </Box>
      {[STATUS.Transcript, STATUS.Generating].includes(status) && (
        <Button
          variant="contained"
          className={classes.start}
          onClick={stopConsultation}
          style={{ alignSelf: 'center' }}
        >
          {STATUS.Generating === status ? 'Generating Note...' : 'Stop & Generate Note'}
          {STATUS.Generating === status && <CircularProgress sx={{ color: 'white', marginLeft: '12px' }} size={20} />}
        </Button>
      )}
      {(showTab || isHistory) && (
        <Box className={classes.bottomBtn}>
          {!isHistory && (
            <ClickAwayListener onClickAway={() => {}}>
              <Tooltip
                title={<img src={Images.feedback} alt="gif" className={classes.feedbackImg} />}
                open={true}
                placement="top"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{
                  disablePortal: true,
                }}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  variant={'contained'}
                  className={classes.feedback}
                  onClick={() => setData({ feedbackModal: true })}
                  startIcon={<img src={Images.startYellowActive} alt="star" />}
                >
                  Feedback
                </Button>
              </Tooltip>
            </ClickAwayListener>
          )}
          {showResume && (
            <Button
              variant={isHistory ? 'contained' : 'outlined'}
              className={classes.startNew}
              onClick={() => startConsultation({ isResume: true, lan: language })}
              style={{ alignSelf: 'center', width: 248 }}
            >
              Resume
            </Button>
          )}
          {index === 0 && (
            <Button
              variant="outlined"
              className={classes.copy}
              onClick={() => copyElementContent('transcriptions')}
              style={{ alignSelf: 'center' }}
            >
              Copy Transcript
            </Button>
          )}
          {!isHistory && (
            <Button
              variant={index === 1 ? 'outlined' : 'contained'}
              className={classes.startNew}
              onClick={() => setData({ showSwitchLanguage: true })}
              style={{ alignSelf: 'center' }}
            >
              Start A New Consultation
            </Button>
          )}
          {index === 1 && !!smartNoteText && (
            <Button variant="contained" className={classes.copy} onClick={onClickCopy} style={{ alignSelf: 'center' }}>
              Copy Note
            </Button>
          )}
        </Box>
      )}
      {blocker.state === 'blocked' && (
        <Dialog open={true}>
          <Box sx={{ padding: '24px' }}>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Leaving site? </Typography>
            <Typography sx={{ margin: '24px 0' }}>Changes you made may not be saved. </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                onClick={() => blocker.reset()}
                color="primary"
                sx={{ width: '120px', marginRight: '16px' }}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={() => blocker.proceed()} color="primary" sx={{ width: '120px' }}>
                Leave
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {backendModal && (
        <Dialog open={true}>
          <Box sx={{ padding: '24px', width: 500 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>Thanks !</Typography>
              <IconButton onClick={() => setData({ backendModal: false, status: STATUS.Init })}>
                <Close htmlColor="#211F1F" />
              </IconButton>
            </Box>
            <Typography sx={{ margin: '24px 0' }}>
              Rest assured, we're working on your transcript and notes. They will show up in your Consultation History
              in a minute. Thanks for using our tool!
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => setData({ backendModal: false, status: STATUS.Init })}
                color="primary"
                sx={{ width: '100px' }}
              >
                Got it
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {feedbackModal && (
        <FeedbackDialog onClose={() => setData({ feedbackModal: false })} ConsultationId={cId as string} />
      )}
      {showPatientTip && <PatientTipDialog onClose={onClosePatientTip} />}
      {showSwitchLanguage && (
        <SwitchLanguageDialog
          onClose={() => {
            setData({ showSwitchLanguage: false })
          }}
          language={language}
          onStart={(language) => {
            setLanguage(language)
            startConsultation({ lan: language })
          }}
        />
      )}
    </Box>
  )
}

export const TranscriptWrapper = () => {
  const { id } = useParams()

  return <Transcript key={id || '0'} />
}
export default Transcript
