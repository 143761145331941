import { Box, Typography, Button, TextField } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import useSetState from 'hooks/useSetState'
import { isValidPassword, isValidEmail } from 'utils/common'
import Images from 'assets'
import { requestResetAuthCode, verifyActionToken, resetPassword } from 'apis'
import { actions } from 'app/profileSlice'
import Storages from 'utils/Storages'
import { useAppDispatch } from 'app/hooks'
import SnackBar from 'components/snackBar'
import styles from './ResetPassword.module.less'
import Config from 'constants/Config'

export default function ResetPassword() {
  const dispatch = useAppDispatch()
  const {
    state: { Email: email },
  } = useLocation()
  const [state, setState] = useSetState({
    Email: email || '',
    Password: '',
    ConfirmPassword: '',
    hasError: false,
    step: 0,
    Code: '',
    ActionTokenId: '',
  })
  const { Email, Password, ConfirmPassword, hasError, step, Code, ActionTokenId } = state
  const navigate = useNavigate()

  const onGetCode = () => {
    let hasError = false
    if (!isValidEmail(Email)) {
      hasError = true
    }
    setState({ hasError })
    if (hasError) return
    requestResetAuthCode({ Email }).then((res: any) => {
      setState({ step: 1 })
      setState({ ActionTokenId: res?.ActionTokenId })
      SnackBar({ msg: 'Successful', type: 'success' })
    })
  }

  const onVerifyActionToken = () => {
    verifyActionToken({ ActionTokenId, AuthCode: Code }).then((res: any) => {
      setState({ step: 2 })
    })
  }

  const onResetPassword = async () => {
    let hasError = false
    if (!isValidPassword(Password).checked) {
      hasError = true
    }
    if (ConfirmPassword !== Password) {
      hasError = true
    }
    setState({ hasError })
    if (hasError) return
    resetPassword({ ActionTokenId, Password1: Password, Password2: ConfirmPassword }).then((res: any) => {
      Storages.setAuthToken(res.UserToken)
      dispatch(actions.loginSuccess(res))
      navigate('/', { replace: true })
    })
  }

  return (
    <Box className={styles.stepContainer}>
      <Typography className={styles.header} sx={{ marginBottom: '60px' }}>
        <img src={Config.logo} alt="logo" className={styles.logo} />
        {Config.appName}
      </Typography>

      {step === 0 && (
        <Box className={styles.card}>
          <img src={Images.password} alt="logo" className={styles.icon} />
          <Typography className={styles.cardTitle} style={{ marginBottom: '16px' }}>
            Forgot Password?
          </Typography>
          <Typography className={styles.text} sx={{ marginBottom: '36px' }}>
            No Worries, Enter your email address
          </Typography>
          <TextField
            value={Email}
            variant="outlined"
            placeholder="Email"
            fullWidth
            onChange={(e) => setState({ Email: e.target.value })}
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            error={(hasError && !isValidEmail(Email)) || false}
            helperText={hasError && !isValidEmail(Email) && 'Please enter a valid email'}
          />

          <Button
            variant="contained"
            className={`${styles.button} ${styles.next}`}
            onClick={onGetCode}
            disabled={!Email}
            classes={{ disabled: styles.disabled }}
          >
            Get Code
          </Button>
        </Box>
      )}
      {step === 1 && (
        <Box className={styles.card}>
          <img src={Images.email} alt="logo" className={styles.icon} />
          <Typography className={styles.cardTitle} style={{ marginBottom: '16px' }}>
            Verify The Email
          </Typography>
          <Typography className={styles.text} sx={{ marginBottom: '36px' }}>
            Paste the code sent to <br />
            {Email}
          </Typography>
          <TextField
            value={Code}
            variant="outlined"
            placeholder="Code"
            fullWidth
            onChange={(e) => setState({ Code: e.target.value })}
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
          />

          <Button
            variant="contained"
            className={`${styles.button} ${styles.next}`}
            onClick={onVerifyActionToken}
            disabled={!Code}
            classes={{ disabled: styles.disabled }}
          >
            Reset Password
          </Button>

          <Typography className={styles.signInRow} sx={{ textAlign: 'center', marginTop: '16px' }}>
            Didn&#39;t receive the email?{' '}
            <span className={styles.signIn} onClick={onGetCode}>
              Resend it
            </span>
          </Typography>
        </Box>
      )}
      {step === 2 && (
        <Box className={styles.card}>
          <img src={Images.newPassword} alt="logo" className={styles.icon} />
          <Typography className={styles.cardTitle} style={{ marginBottom: '16px' }}>
            Set a new password
          </Typography>
          <Typography className={styles.text} sx={{ marginBottom: '36px' }}>
            Your new password must be different from the one you used before.
          </Typography>

          <Typography className={styles.label}>Password</Typography>
          <TextField
            variant="outlined"
            placeholder="Password"
            autoComplete="new-password"
            fullWidth
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            type="password"
            onChange={(e) => setState({ Password: e.target.value })}
            error={(hasError && !isValidPassword(Password).checked) || false}
            helperText={hasError && !isValidPassword(Password).checked && isValidPassword(Password).message}
          />

          <Typography className={styles.label}>Confirm Password</Typography>
          <TextField
            variant="outlined"
            placeholder="Please enter"
            fullWidth
            className={styles.input}
            InputProps={{ classes: { root: styles.inputRoot } }}
            type="password"
            onChange={(e) => setState({ ConfirmPassword: e.target.value })}
            error={hasError && ConfirmPassword !== Password}
            helperText={hasError && ConfirmPassword !== Password && 'Password does not match'}
          />

          <Button
            variant="contained"
            className={`${styles.button} ${styles.next}`}
            onClick={onResetPassword}
            disabled={!Password || !ConfirmPassword}
            classes={{ disabled: styles.disabled }}
          >
            Confirm And Log In
          </Button>
        </Box>
      )}
    </Box>
  )
}
