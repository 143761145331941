import React, { useState } from 'react'
import { Button, Dialog as DialogUi, IconButton, Typography } from '@mui/material'
import classes from './LogOutDialog.module.less'
import { Close } from '@mui/icons-material'
import Storages from 'utils/Storages'
import Images from 'assets'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { actions, selectProfile } from 'app/profileSlice'
import { logout } from 'apis'
import SnackBar from '../snackBar'
import Config from '../../constants/Config'

interface Props {
  onClose: () => void
}
const LogOutDialog: React.FC<Props> = ({ onClose }) => {
  const profile = useAppSelector(selectProfile)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [open, setOpen] = useState(true)
  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onLogout = async () => {
    await logout()
      .then(() => {
        dispatch(actions.loginOut())
        Storages.removeAllDate()
        navigate('/signUp', { replace: true })
        SnackBar({ msg: 'Successfully logout', type: 'success' })
      })
      .catch((res) => {
        SnackBar({ msg: res })
      })
  }

  const onCancel = () => {
    removeDialog()
  }

  return (
    <DialogUi
      open={open}
      onClose={removeDialog}
      classes={{ paper: classes.container }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={onCancel} classes={{ root: classes.close }}>
        <Close htmlColor="#211F1F" />
      </IconButton>
      <img src={Config.logo} className={classes.logo} />
      <Typography className={classes.userName}>{profile?.user?.UserName}</Typography>
      <Button variant="contained" onClick={onLogout} color="primary" className={classes.btn}>
        Logout
      </Button>
    </DialogUi>
  )
}

export default LogOutDialog
