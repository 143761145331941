import React, { ReactNode } from 'react'
import { Box, Theme, Typography } from '@mui/material'
import classnames from 'classnames'
import classes from './LabelItem.module.less'
import { SxProps } from '@mui/system'

interface Props {
  label: string
  className?: string
  children?: ReactNode
  sx?: SxProps<Theme>
}
const LabelItem: React.FC<Props> = ({ label, children, className, sx }) => {
  return (
    <Box className={classnames(classes.item, className)} sx={sx}>
      <Typography className={classes.label}>{label}</Typography>
      <Box className={classes.content}>{children}</Box>
    </Box>
  )
}

export default LabelItem
