import React, { useState } from 'react'
import { IconButton, Popover } from '@mui/material'
import Images from '../../assets'
import { isMobile } from 'utils/common'
import IcdEditModal, { IcdEditContent } from './IcdEditModal'
import classes from './IcdEdit.module.less'
import { ICDDataITF } from '../../types/transcription'
interface Props {
  cId: string
  icds: ICDDataITF[]
  onConfirm: (data: ICDDataITF[]) => void
}
const IcdEdit: React.FC<Props> = ({ cId, icds, onConfirm }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile) {
      setDialogOpen(true)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDialogOpen = () => {
    setDialogOpen(false)
  }
  const open = Boolean(isMobile ? dialogOpen : anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <IconButton onClick={handleClick}>
        <img src={Images.add} />
      </IconButton>
      {isMobile && open && <IcdEditModal onCancel={handleDialogOpen} icds={icds} cId={cId} onConfirm={onConfirm} />}
      {!isMobile && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {open && <IcdEditContent onCancel={handleClose} cId={cId} icds={icds} onConfirm={onConfirm} />}
        </Popover>
      )}
    </>
  )
}

export default IcdEdit
