import React, { useState, useRef, useLayoutEffect } from 'react'
import { Box, Button, Dialog as DialogUi, IconButton, Typography, Drawer } from '@mui/material'
import classes from './EditTagDialog.module.less'
import { Close, AddCircle } from '@mui/icons-material'
import { updateConsultation } from 'apis'
import SnackBar from '../snackBar'
import { useSetState } from 'hooks'
import Images from 'assets'
import { isMobile } from 'utils/common'

interface Props {
  onClose: () => void
  data: string[]
  onOk: (data: string[]) => void
  field: string
  id: string
}

const EditTagDialog: React.FC<Props> = ({ onClose, data, onOk, field, id }) => {
  const [state, setState] = useState<string[]>(JSON.parse(JSON.stringify(data || [''])))
  const [open, setOpen] = useState(true)
  const newTagRef = useRef<HTMLSpanElement>(null)
  const [focus, setFocus] = useState(false)

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onCancel = () => {
    removeDialog()
  }
  const onSave = () => {
    const newData = state.filter((v) => v)
    updateConsultation({ ConsultationId: id, [field]: newData }).then(() => {
      SnackBar({ type: 'success', msg: 'Successfully Edit' })
      onOk(newData)
      onCancel()
    })
  }
  useLayoutEffect(() => {
    if (newTagRef.current) {
      newTagRef.current.focus()
    }
  }, [focus])

  const Com = (
    <>
      <IconButton onClick={onCancel} classes={{ root: classes.close }}>
        <Close htmlColor="#211F1F" />
      </IconButton>
      <Typography className={classes.title}>
        {field === 'Medicine' ? 'Medications/Procedures' : 'Diseases/Conditions'}
      </Typography>
      <Box className={classes.box}>
        {state.map((item, index) => {
          return (
            <div key={index} className={classes.tagItem}>
              <span
                className={classes.textInput}
                contentEditable
                suppressContentEditableWarning
                ref={index === state.length - 1 ? newTagRef : null}
                onBlur={(e) => {
                  state[index] = e.currentTarget.innerText
                  setState([...state])
                }}
              >
                {item}
              </span>
              <Close
                htmlColor="#5A5AF6"
                sx={{ fontSize: '24px' }}
                onClick={() => {
                  state.splice(index, 1)
                  setState([...state])
                }}
              />
            </div>
          )
        })}
        <AddCircle
          htmlColor="#3333F5"
          sx={{ cursor: 'pointer', height: '38px' }}
          onClick={() => {
            state.push('')
            setState([...state])
            setFocus(!focus)
          }}
        />
      </Box>
      <Box className={classes.bottom}>
        <Button variant="outlined" onClick={onCancel} color="primary" className={classes.btn}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave} color="primary" className={classes.btn}>
          Save
        </Button>
      </Box>
    </>
  )
  if (isMobile) {
    return (
      <Drawer anchor="bottom" open={open} onClose={removeDialog} classes={{ paper: classes.h5container }}>
        {Com}
      </Drawer>
    )
  }

  return (
    <DialogUi disableEscapeKeyDown open={open} onClose={removeDialog} classes={{ paper: classes.container }}>
      {Com}
    </DialogUi>
  )
}

interface TagProps {
  keywords: Record<string, string[]>
  setData: (value: any) => void
  cId: string
}
export const TagCom: React.FC<TagProps> = ({ keywords = {}, cId, setData }) => {
  const [{ open, data, field }, setState] = useSetState({ open: false, data: [] as string[], field: '' })
  const Disease = keywords.Disease || []
  const Medicine = keywords.Medicine || []
  return (
    <>
      <Typography
        className={classes.tagComTitle}
        onClick={() => {
          setState({ open: true, data: Disease, field: 'Disease' })
        }}
      >
        Diseases/Conditions <img src={Images.edit} alt="" width="24" style={{ marginLeft: '12px' }} />
      </Typography>
      <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
        {Disease.map((item, index) => {
          return (
            <span key={index} className={classes.tagComItem}>
              {item}
            </span>
          )
        })}
      </Box>

      <Typography
        className={classes.tagComTitle}
        onClick={() => {
          setState({ open: true, data: Medicine, field: 'Medicine' })
        }}
      >
        Medications/Procedures <img src={Images.edit} alt="" width="24" style={{ marginLeft: '12px' }} />
      </Typography>
      <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
        {Medicine.map((item, index) => {
          return (
            <span key={index} className={classes.tagComItem}>
              {item}
            </span>
          )
        })}
      </Box>

      {open && (
        <EditTagDialog
          id={cId}
          data={data}
          field={field}
          onClose={() => setState({ open: false })}
          onOk={(data) => setData({ keywords: { ...keywords, [field]: data } })}
        />
      )}
    </>
  )
}

export default EditTagDialog
