import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig, i18nChangeLanguage } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'
import classnames from 'classnames'
import { uploadPublicFile } from 'utils/uploadFile'
import { useAppSelector } from 'app/hooks'
import { selectProfile } from 'app/profileSlice'
import classes from './RichText.module.less'

i18nChangeLanguage('en')
// 工具栏配置
const toolbarConfig: Partial<IToolbarConfig> = {
  excludeKeys: ['|', 'fontFamily', 'emotion', 'group-video', 'insertTable', 'fullScreen'],
}
interface RichTextEditProps {
  onSubmit: (html: string) => void
  htmlContent?: string
  className?: string
  editorStyle?: React.CSSProperties
  disabledEdit?: boolean
}

// api https://www.wangeditor.com/v5/for-frame.html#demo-2
const RichText: React.FC<RichTextEditProps> = ({ onSubmit, htmlContent, disabledEdit, className, editorStyle }) => {
  const profile = useAppSelector(selectProfile)
  const { user } = profile

  // editor 实例
  const [editor, setEditor] = useState<IDomEditor | null>(null)

  // 编辑器内容
  const [html, setHtml] = useState(htmlContent || '')

  // 编辑器配置
  const editorConfig: Partial<IEditorConfig> = {
    placeholder: '',
    readOnly: disabledEdit,
    MENU_CONF: {
      uploadImage: {
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 100 * 1024 * 1024, //100M
        async customUpload(file: File, insertFn: any) {
          const url = await uploadPublicFile(file, `${user?.pcId}/html/${Date.now()}_${file.name}`)
          insertFn(url, '', '')
        },
      },
    },
  }

  useEffect(() => {
    onSubmit(html)
  }, [html])
  useEffect(() => {
    setHtml(htmlContent || '')
  }, [htmlContent])

  useEffect(() => {
    if (editor) {
      editor.on('modalOrPanelShow', (modalOrPanel) => {
        //隐藏 Image link
        const elements = document.getElementsByClassName('babel-container')
        for (let i = 0; i < elements?.length; i++) {
          //@ts-ignore
          if (elements[i].firstChild?.innerHTML === 'Image link') {
            //@ts-ignore
            elements[i].style.display = 'none'
          }
        }
      })
    }
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
    }
  }, [editor])
  return (
    <Box className={classnames(classes.rootContainer, className)}>
      <Toolbar
        editor={editor}
        defaultConfig={toolbarConfig}
        mode="default"
        style={{ borderBottom: '1px solid #ccc' }}
      />
      <Editor
        defaultConfig={editorConfig}
        value={html}
        onCreated={setEditor}
        onChange={(editor) => {
          setHtml(editor.getHtml())
        }}
        mode="default"
        className={classes.editor}
        style={editorStyle}
      />
    </Box>
  )
}

export default RichText
