import React, { useCallback } from 'react'
import classes from './IcdEditModal.module.less'
import { Box, Button, Checkbox, CircularProgress, Drawer, InputAdornment, Typography } from '@mui/material'
import { useSetState } from '../../hooks'
import { Close, Search } from '@mui/icons-material'
import CustomTextField from '../common/CustomTextField'
import { useDebouncedCallback } from 'use-debounce'
import { SxProps } from '@mui/system'
import { getIcdData, updateConsultation } from '../../apis'
import { ICDDataITF } from '../../types/transcription'
import classnames from 'classnames'
import SnackBar from '../snackBar'
interface IcdEditContentProps {
  cId: string
  icds: ICDDataITF[]
  sx?: SxProps
  isMobile?: boolean
  onCancel?: () => void
  onConfirm: (data: ICDDataITF[]) => void
}
export const IcdEditContent: React.FC<IcdEditContentProps> = ({ sx, isMobile, onCancel, cId, icds, onConfirm }) => {
  const [{ st, loading, list, selects }, setData] = useSetState<{
    loading: boolean
    st?: string
    list: ICDDataITF[]
    selects: ICDDataITF[]
  }>({ list: [], loading: false, selects: [] })
  const getData = useDebouncedCallback((st: string) => {
    setData({ loading: true })
    const exIds = (icds || []).map((item) => item.pcId)
    getIcdData<{ Icds: ICDDataITF[] }>({
      skip: 0,
      take: 50,
      st: st,
      exIds: JSON.stringify(exIds),
    }).then((res) => {
      setData({
        list: res.Icds || [],
        loading: false,
      })
    })
  }, 500)
  const onInputChange = useCallback(
    (value: string, name: string) => {
      setData({ st: value })
      getData(value)
    },
    [getData, setData],
  )
  const onSaveCall = useCallback(() => {
    const nextData = [...(icds || []), ...selects]
    updateConsultation({ ConsultationId: cId, Icds: nextData }).then(() => {
      SnackBar({ type: 'success', msg: 'Successfully Edit' })
      onConfirm?.(nextData)
      onCancel?.()
    })
  }, [cId, icds, onCancel, onConfirm, selects])
  return (
    <Box className={classes.container} sx={sx}>
      <CustomTextField
        placeholder="Search"
        value={st}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          classes: {
            root: classes.inputSearch,
          },
        }}
        onInputChange={onInputChange}
      />
      <Box
        className={classnames(classes.content, {
          [classes.emptyContent]: loading || (!loading && (!st || list.length === 0)),
        })}
      >
        {loading && <CircularProgress />}
        {!loading && (!st || list.length === 0) && (
          <Typography className={classes.emptyText}>{!st ? 'Please input search' : 'No options'}</Typography>
        )}
        {!loading &&
          list.length > 0 &&
          list.map((item) => {
            const { pcId, Name, Code } = item
            return (
              <Box key={pcId} className={classes.item}>
                <Checkbox
                  size="small"
                  sx={{ padding: '14px 12px 14px 8px' }}
                  onChange={(e) => {
                    const isSelected = e.target.checked
                    if (isSelected) {
                      selects.push(item)
                    } else {
                      const index = selects.findIndex((item) => item.pcId === pcId)
                      selects.splice(index, 1)
                    }
                    setData({ selects: [...selects] })
                  }}
                />
                <Typography className={classes.itemText}>{`${Name}(${Code})`}</Typography>
              </Box>
            )
          })}
      </Box>
      <Box className={classes.bottom}>
        <Button
          variant="outlined"
          onClick={onCancel}
          color="primary"
          className={classnames(classes.btn, { [classes.btnMobile]: isMobile })}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSaveCall}
          color="primary"
          disabled={selects.length === 0}
          className={classnames(classes.btn, { [classes.btnMobile]: isMobile })}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  )
}
interface Props {
  onCancel: () => void
  cId: string
  icds: ICDDataITF[]
  onConfirm: (data: ICDDataITF[]) => void
}
const IcdEditModal: React.FC<Props> = ({ onCancel, cId, icds, onConfirm }) => {
  const [{ open }, setState] = useSetState({
    open: true,
  })
  return (
    <Drawer anchor="bottom" open={open} classes={{ paper: classes.h5UpdatePaper }}>
      <Box className={classes.dialogTitle}>
        <Typography className={classes.title} style={{ fontSize: 24 }}>
          Add ICD-10 CODE
        </Typography>
        <Close htmlColor="#211F1F" sx={{ fontSize: 30 }} onClick={onCancel} />
      </Box>
      <IcdEditContent
        sx={{ padding: '16px 0 0 !important' }}
        isMobile
        onCancel={onCancel}
        icds={icds}
        cId={cId}
        onConfirm={onConfirm}
      />
    </Drawer>
  )
}
export default IcdEditModal
